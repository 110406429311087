<!--
 * @Author: 刘锦
 * @Date: 2020-09-18 11:55:30
 * @LastEditTime: 2022-05-26 16:26:57
 * @LastEditors: Seven
 * @Description:
 * @FilePath: \workbench-front\src\components\business\goods-modal.vue
-->
<template>
  <div class="drawer-container">
    <el-drawer title="选择商品" :visible.sync="drawerVisible" size="90%"  :before-close="close">
      <div class="drawer-body1" ref="contentTop">
        <!-- 搜索表单区 -->
        <el-form
          ref="goodsForm"
          :model="goodsForm"
          :rules="goodsFormRules"
          label-position="left"
          label-width="70px"
          class="tableSearch formContent"
          inline
        >
        <el-row :gutter="24">
          <el-col v-for="item in goodsFormItems" :key="item.label" :span="4">
            <el-form-item
              class="searchItem"
              :style="item.itemStyle"
              :prop="item.prop"
            >
              <v-autocomplete
                style="width: 100%"
                v-if="item.type === 'select' && item.prop=='productId'"
                v-model="goodsForm[item.prop]"
                :items="item.options"
                :menu-props="{ bottom: true, offsetY: true }"
                :label="item.label"
                :disabled="item.disabled"
                :style="item.style"
                :readonly="productIdReadOnly"
                outlined
                item-text="label"
                item-value="value"
                :dense="true"
                :height="32"
                :clearable="!productIdReadOnly"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
                @change="onProductIdChange"
              ></v-autocomplete>
              <v-text-field
                style="width: 100%"
                v-else
                v-model="goodsForm[item.prop]"
                :label="item.label"
                :disabled="item.disabled"
                :readonly="item.readonly"
                :style="item.style"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                suffix-icon="el-icon-search"
                @click="onInputFocus(item.prop)"
                @click:clear="onInputClear(item.prop)"
                ref="cateInputRef"
              >
              </v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <v-text-field
              v-model="goodsForm.goodsName"
              label="商品名称"
              hide-details="auto"
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
          </el-col>
          <el-col :span="4">
            <v-text-field
              v-model="goodsForm.goodsId"
              label="商品ID"
              hide-details="auto"
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
          </el-col>
          <!-- <el-col :span="4">
              <v-autocomplete
                v-model="goodsForm.keywordsKey"
                :items="[{ label: '商品名称', value:'goodsName' }, { label:'商品ID',value:'goodsId' }]"
                :menu-props="{ bottom: true, offsetY: true }"
                label="关键词"
                outlined
                item-text="label"
                item-value="value"
                :dense="true"
                :height="32"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
              ></v-autocomplete>
            </el-col>
            <el-col :span="4">
         
              <v-text-field
                v-model="goodsForm.keywordsValue"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-col> -->
        </el-row>
     
          <div class="action-btns">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </div>
        </el-form>
        <div class="goods-table-wrapper">
          <!-- 商品表格区 -->
          <el-table
            style="width: 100%; margin-bottom: 24px"
            border
            stripe
            size="mini"
            element-loading-text="拼命加载中"
            :row-style="{ height: '20px' }"
            :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e5f2ff' }"
            sortable
            highlight-current-row
            header-cell-class-name="normal-table-header"
            :height="tableHeight"
            :data="tableData"
            @current-change="onCurrentChange"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          >
        
            <el-table-column :show-overflow-tooltip="true" width="40px">
              <el-checkbox :checked="true" disabled></el-checkbox>
            </el-table-column>
            <template v-for="item of columns">
              <el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'smallPic'" :key="item.prop" :label="item.label" width="100px">
                <template v-slot:default="{ row: { smallPic } }">
                  <img :src="smallPic" alt="" style="width: 100%; height: 60px; object-fit: contain" />
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'statusGoods'" :key="item.prop" :label="item.label">
                <template v-slot:default="{ row: { statusGoods } }">
                  <a tag :type="GOODS_STATUS_MAP[statusGoods].type"> {{ GOODS_STATUS_MAP[statusGoods].label }}</a>
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'goodsRank'" :key="item.prop" :label="item.label" width="160px">
                <template v-slot:default="{ row }">
                  {{ GOODS_RANK_MAP[row.goodsRank] }}
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'updateTime'" :key="item.prop" :label="item.label" width="160px">
                <template v-slot:default="{ row }">
                  {{ row.updateTime | formatDate }}
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'goodsSeriesName'" :key="item.prop" :label="item.label" width="160px">
                <template v-slot:default="{ row }">
                  {{ row.goodsSeriesName || '--'}}
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="true"
                v-else
                :key="item.label"
                :label="item.label"
                :prop="item.prop"
                :width="item.width || '160px'"
                :fixed="item.fixed"
              />
            </template>
          </el-table>
          <Pagination
            :page.sync="page"
            :pageSize.sync="pageSize"
            :totalPage="totalPage"
            :totalCount="totalCount"
            @pageChange="pageChange"
            @pageSizeChange="pageSizeChange"
          />
        </div>
      </div>
      <div class="drawer-footer">
        <el-button @click="onCancel">返回</el-button>
        <el-button type="primary" @click="onOk" :disabled="!isCurrentRow">确定</el-button>
      </div>
    </el-drawer>

    <goods-cate-modal
      :visible.sync="goodsCateModalVisible"
      :businessId="selectGoodsType==5?'0':'1'"
      @on-cancel="onGoodsCateCancel"
      @on-ok="onGoodsCateOk"
      :goodBusinessId="goodBusinessId"
    />
  </div>
</template>

<script>
import Checker from '@/utils/Checker'
import goodsCateModal from "./goods-cate-modal"
import { goodModalTypeEnum } from '../constants'
export default {
  name: "GoodsModal",
  props: {
    visible: Boolean,
    goodsForm: Object,
    goodsFormRules: Object,
    goodsFormItems: Array,
    businessId: [String, Number],
    orderTable: Object,
    type: String,
    multiChoice: Boolean,//升班：是否为多选

    //选择途径 (1 网校后台 2 全网包名，3全网转班  4全网升班 5.成考第二志愿)
    selectGoodsType: {
      required: false,
      type: String
    }, 
    //成考的第一志愿报考专业
    enrollingMajorId: {
      required:false,
      type:   [Number, String]
    }, 
    //成考的第一志愿报考学级
    enrollingGradation:{
      required: false,
      type: [Number, String] 
    },
    //成考第一志愿报考产品线:
    enrollingProductId:{
      required: false,
      type: Number 
    },
    //校区id
    schoolId: {
      required: false,
      type: String
    }
  },
  components: {
    goodsCateModal,
    Pagination: ()=>import("./pagination.vue")
  },
  data() {
    const columns=  [
      { label: "商品ID", prop: "goodsId", width: '65px' },
      { label: "封面", prop: "smallPic" },
      { label: "商品名称", prop: "goodsName" },
      { label: "产品线", prop: "productFullName" },
      { label: "商品分类", prop: "goodsCategoryName" },
      { label: "商品类型", prop: "goodsTypeName" },
      { label: "产品系列", prop: "goodsSeriesName" },
      { label: "上课形式", prop: "classForm" },
      { label: "商品级别", prop: "goodsRank" },
      { label: "商品售价", prop: "goodsPriceScope" },
      { label: "商品状态", prop: "statusGoods" },
      { label: "更新时间", prop: "updateTime" },
    ]
    // 转班不需要显示产品系列 商品级别
    if(this.type === goodModalTypeEnum.transfer){
      columns.splice(6, 1)
      columns.splice(7, 1)
    // 升班、报名都不需要显示商品级别
    }else if(this.type === goodModalTypeEnum.upgrade || this.type === goodModalTypeEnum.signup){
      columns.splice(8, 1)
    }
    return {
      productIdReadOnly: false,
      drawerVisible:false,
      tableData: [],
      currentRadioIndex: "",
      columns,
      page: 1,
      pageSize: 10,
      totalPage: 1,
      totalCount: 1,
      goodsCateModalVisible: false,
      GOODS_STATUS_MAP: { 0: { label: "正常", type: "success" }, 1: { label: "异常", type: "error" } },
      GOODS_RANK_MAP: {
        1: "单品-默认规格",
        2: "单品-多规格",
        3: "套餐-默认规格",
        4: "套餐-多规格单选",
        5: "套餐-多规格多选",
      },
      currentRow: {},
      goodBusinessId: '',//选择的产品线的对应businessId
      topHeight: ''
    }
  },
  methods: {

    close(){
      this.$emit('update:visible', false)
    },

    keywordsChange( data ){
      this.goodsForm.keywordsKey = data;
      this.goodsForm.keywordsValue = "";
    },

    onCancel() {
      this.$emit("update:visible", false)
    },
    onOk() {
      let row = JSON.parse(JSON.stringify(this.currentRow))
      console.log('row', row)
      if(this.type == 'upgrade') {
        console.log('[升班，类型判断] 多升一:', this.multiChoice);
        console.table([
          {name: 'order', goodsCategoryId: this.orderTable.goodsCategoryId, goodsSeriesId: this.orderTable.goodsSeriesId},
          {name: 'row', goodsCategoryId: row.goodsCategoryId, goodsSeriesId: row.goodsSeriesId}
        ])
        if( !this.multiChoice ? 
          // 升班通过条件 
          // 1 升 1，售后订单同商品分类或同产品系列
          !(+row.goodsCategoryId === +this.orderTable.goodsCategoryId || +row.goodsSeriesId === +this.orderTable.goodsSeriesId)
          // 多升 1，售后主订单同赛道（财经）商品
          : +this.orderTable.businessId != +row.businessId
        ){
          this.$message.error("升班，只能选择与售后订单相同的商品分类或产品系列的商品")
          return
        }
        
      }
      this.$emit("on-ok", row)
    },
    // 查询
    onSearch() {
      this.page = 1
      let paramsGoodsId = this.goodsForm.goodsId == undefined ? "" : this.goodsForm.goodsId
      let codeNum = this.checkNumber(paramsGoodsId)
      if (codeNum == true) {
        this.getTableData()
      } else {
        this.$message.error("请输入正确的商品ID")
      }
    },
    checkNumber(e) {
      console.log("e商品", typeof e, e != "")
      var re = /^\d+$/
      if (e != "" || e == undefined) {
        if (!re.test(e)) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    // 重置
    onReset() {
      this.page = 1
      Object.keys(this.goodsForm).forEach((key) => (this.goodsForm[key] = ""))
      this.getTableData()
    },
    onCurrentChange(row) {
      this.currentRow = row
    },
    onRadioChange(index, row) {
      this.currentRadioIndex = index
      this.currentRow = row
    },
    pageChange() {
      this.getTableData()
    },
    pageSizeChange() {
      this.page = 1
      this.getTableData()
    },
    // 商品分类点击展示商品分类弹窗
    onInputFocus(prop) {
      if (prop === "goodsCategoryName") {
        this.goodsCateModalVisible = true
      }
    },
    onInputClear(prop) {
      if (prop === "goodsCategoryName") {
        this.goodsForm.goodsCategoryId = ""
        this.$refs.cateInputRef[0] && this.$refs.cateInputRef[0].blur()
      }
    },
    // 商品分类弹窗取消回调
    onGoodsCateCancel() {
      this.goodsCateModalVisible = false
    },
    // 商品分类弹窗确定回调
    onGoodsCateOk(cateRow) {
      this.goodsForm.goodsCategoryName = cateRow.name
      this.goodsForm.goodsCategoryId = cateRow.id
      this.goodsCateModalVisible = false
    },
    // 获取商品列表表格数据
    async getTableData() {
      let extra = {
        goodsCategoryIdForSchool: '',
        goodsSeriesIdForSchool: ''
      }


      if(this.type == goodModalTypeEnum.upgrade){
        /** 升班
         * 1、【全网控制】
          （1）1 升 1，限制同分类或同产品系列商品
          （2）多升 1，限制同赛道商品（财经）
         */
        if(!this.multiChoice && this.orderTable){
          extra.goodsCategoryIdForSchool = this.orderTable.goodsCategoryId
          extra.goodsSeriesIdForSchool = this.orderTable.goodsSeriesId
        }else{
          extra.goodsCategoryIdForSchool = ''
          extra.goodsSeriesIdForSchool = ''
        }
      }else{
        extra.goodsSeriesIdForSchool =  this.orderTable != undefined ? this.orderTable.goodsSeriesId : ""
      }
      const params = {
        businessId: this.businessId, //null
        pageSize: this.pageSize,
        curPage: this.page,
        schoolId: this.schoolId,
        ...extra,
        ...this.goodsForm,
        statusSchool: 1
      }
      params.goodsName = this.goodsForm.goodsName
      
      if(this.type === goodModalTypeEnum.transfer || this.type == goodModalTypeEnum.upgrade){
        params.selectGoodsType = this.selectGoodsType
      }
      //针对成考第二志愿增加限制
      let IS_CK = '5';
      params.selectGoodsType = this.selectGoodsType;
      if( `${this.selectGoodsType}` === IS_CK ){
        params.enrollingMajorId = this.enrollingMajorId;
        params.enrollingGradation = this.enrollingGradation;
        params.enrollingProductId = this.enrollingProductId;
      }
      if( params["keywordsKey"] ){
        // params[`${params["keywordsKey"]}`] = params["keywordsValue"]
      }
      delete params.keywordsKey;
      delete params.keywordsValue;
      
      console.log("查询商品得参数", params)
      const { data, pageInfo } = await this.$fetch("getGoodsList", params)
      this.tableData = data
      this.totalPage = pageInfo.pageCount
      this.totalCount = pageInfo.total
    },


    
    async gethandel() {

      this.getTableData()
      await Promise.all([
        this.$store.dispatch("getProjectList", this.schoolId),
        
        this.$store.dispatch("getGoodsRank"),
        this.$store.dispatch("getGoodsTypes"),
      ])
      for (const item of this.goodsFormItems) {
        if (item.prop === "productId") {
          item.options = this.projectList.map((item) => ({ label: item.fullName, value: item.id, businessId: item.businessId }))
        } else if (item.prop === "goodsRank") {
          item.options = this.goodsRank
        } else if (item.prop === "goodsTypeId") {
          item.options = this.goodsTypes.map((item) => ({ value: item.id, label: item.name }))
        }
      }
    },
    // 商品类型更改
    onProductIdChange(productId){
      console.log('[onProductIdChange]', productId);
      if(productId === null) return this.goodBusinessId = ''
      const item = this.projectList.find(item => productId == item.id)
      if(item){
        this.goodBusinessId = item.businessId
      }
    }
  },
  mounted() {
    // this.gethandel()
  },
  computed: {
    isCurrentRow() {
      if (!this.currentRow) return false
      return !!Object.keys(this.currentRow).length
    },
    projectList() {
      return this.$store.state.workbench.projectList
    },
    goodsRank() {
      return this.$store.state.workbench.goodsRank
    },
    goodsTypes() {
      return this.$store.state.workbench.goodsTypes
    },
    tableHeight() {
      return window.outerHeight - 420 + 'px'
    }
  },
  watch: {
    visible: {
      async handler(val) {
        this.drawerVisible = val;
         this.productIdReadOnly = false;
         this.goodsForm.productId =""
        if( this.selectGoodsType == 5 ){
          this.goodsForm.productId = this.enrollingProductId
          this.productIdReadOnly = true;
        }
 

        if (!val) return
        this.goodsForm.keywordsKey = "goodsName";
        this.goodsForm.keywordsValue = "";
        this.getTableData()
        await Promise.all([
          this.$store.dispatch("getProjectList", this.schoolId),
          this.$store.dispatch("getGoodsRank"),
          this.$store.dispatch("getGoodsTypes"),
        ])
        for (const item of this.goodsFormItems) {
          if (item.prop === "productId") {
            item.options = this.projectList.map((item) => ({ label: item.fullName, value: item.id, businessId: item.businessId }))
          } else if (item.prop === "goodsRank") {
            item.options = this.goodsRank
          } else if (item.prop === "goodsTypeId") {
            item.options = this.goodsTypes.map((item) => ({ value: item.id, label: item.name }))
          }
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.drawer-container {

  .drawer-body1 {
    height: 100%;
    // display: flex;
    // flex-direction: column;
    padding-top: 10px;
    .formContent {
      // display: flex;
      // flex-wrap: wrap;
    }
    .tableSearch {
      padding: 0 30px 0 30px;
      .searchItem {
        // display: flex;
        // width: 300px;
        width: 100%;
      }
      .action-btns {
        > .el-form-item {
          ::v-deep .el-form-item__label {
            width: 0px !important;
          }
        }
      }
    }
    .goods-table-wrapper {
      flex: 1;
      width: 100%;
      padding: 0 30px 100px;
      margin-top: 10px;
      ::v-deep .el-radio__label {
        display: none;
      }
      ::v-deep .current-row>td{
        background-color: #bfdffe;
      }
      ::v-deep .el-checkbox{
        .el-checkbox__input.is-checked .el-checkbox__inner{
          background-color: #fff;
          border-color:#DCDFE6;
        }
        .el-checkbox__input.is-disabled .el-checkbox__inner::after, .el-checkbox__input.is-disabled, .el-checkbox__input.is-disabled .el-checkbox__inner{
          cursor: initial;
        }
        .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
          border-color:#fff;
        }
      }
      ::v-deep .current-row .el-checkbox{
        .el-checkbox__input.is-checked .el-checkbox__inner{
          background-color: #409EFF;
          border-color:#409EFF;
        }
      }
    }
    ::v-deep .el-radio__label {
      display: none;
    }
  }
  .drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: center;
    background-color: white;
  }
}
::v-deep .el-radio__label {
  display: none;
}
.el-radio__label {
  display: none;
}
.drawer-enter-active,
.drawer-leave-active {
  transition: 0.3s;
}
.drawer-enter {
  transform: translate(100%);
}
.drawer-leave-to {
  transform: translate(100%);
}
</style>
